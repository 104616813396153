import { destr, safeDestr } from "destr";
import SplitType from 'split-type'

export default function splitTipInit() {
	const attr = '[js-split-text]'

	const els = document.querySelectorAll(attr)
	if (!els.length) return

	els.forEach(el => {
		const props = destr(el.getAttribute('js-props')) || {}

		const instance = SplitType.create(el, {
			types: 'char',
			...props
		})
		el.instance = instance

	})
}

import { gsap } from "gsap";
import { findAll } from "../utils/dom.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import animateFrom from "./animateFrom.js";
import { DEV } from "../utils/constants.js";


export function addScrollTrigger(elem, callback, leaveCallback) {
	const dataTrigger = elem.getAttribute('data-trigger')
	const start = "top bottom-=100px"
	// console.log(dataTrigger, elem)
	// gsap.set(elem, { opacity: 0 })
	console.log(callback)
	ScrollTrigger.create({
		trigger: dataTrigger || elem,
		once: true,
		start: start,
		refreshPriority: 10,
		//  markers: true,
		onEnter: function () {

			const time = DEV ? 1000 : 0
			setTimeout(() => {
				callback
					? callback()
					: animateFrom(elem)
			}, time);

		},
		// onEnterBack: function () {
		// 	animateFrom(elem, -1)
		// },
		onLeave: function () {
			leaveCallback && leaveCallback()
		} // assure that the element is hidden when scrolled into view


	});
}

export default function animaAoScroll() {
	findAll("[js-anima]")
		.forEach((el) => addScrollTrigger(el));


}
import gsap from "gsap/gsap-core"
import { addScrollTrigger } from "./animaAoScroll";
import { destr } from "destr";
import { easeDefault } from "../utils/constants";

export function animationChar() {
	const els = document.querySelectorAll('[js-split-text]')
	if (!els.length) return

	els.forEach(el => {
		const animateChars = () => {
			const props = destr(el.getAttribute('js-props')) || {}
			const timelineProps = destr(el.getAttribute('js-timeline-props')) || {}
			console.log({ timelineProps })
			const chars = el.querySelectorAll('.char')

			const tl = gsap.timeline({ ease: easeDefault, ...timelineProps })
				.fromTo(chars, {
					opacity: 0,
					x: -20
				}, {
					opacity: 1,
					x: 0,
					stagger: 0.05,
					...props
				})
			return tl
		}

		addScrollTrigger(el, animateChars)

	})


}
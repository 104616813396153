import gsap from "gsap/gsap-core";
import { easeDefault } from "../utils/constants";

export default function animationParalax(el) {
	// Get the container's bounding rectangle
	const container = document.querySelector(el.getAttribute("js-parallax"));
	let rect = container.getBoundingClientRect();

	// Initialize mouse object with initial values
	const mouse = { x: 0, y: 0, moved: false, leave: false };

	// Add mousemove event listener to the container
	container.addEventListener('mousemove', function (e) {
		mouse.moved = true;
		mouse.leave = false;
		mouse.x = e.clientX - rect.left;
		mouse.y = e.clientY - rect.top;
	});

	container.addEventListener('mouseleave', function (e) {
		mouse.moved = true;
		mouse.leave = true;
	});
	gsap.to(el, { x: 0, y: 0 })

	// Ticker event will be called on every frame
	gsap.ticker.add(function () {
		if (mouse.moved) {
			const paraValue = mouse.leave ? 0 : 1;
			const easing = mouse.leave ? easeDefault : easeDefault;
			const speed = el.getAttribute('data-duration') || (mouse.leave ? 1 : 1)
			parallaxIt(el, paraValue, speed, easing);
		}
		mouse.moved = false;
	});

	// Function to apply parallax effect to target elements
	function parallaxIt(target, movement, speed, easing) {
		const spedo = target.getAttribute('data-speed') || 20
		const x = ((mouse.x - rect.width) / rect.width * movement) * spedo
		const y = ((mouse.y - rect.height) / rect.height * movement) * spedo

		gsap.to(target, {
			duration: speed,
			ease: easing,
			x,
			y
		});
	}


	// Add resize and scroll event listeners to the window
	window.addEventListener('resize', updateRect);
	window.addEventListener('scroll', updateRect);

	// Function to update the container's bounding rectangle on resize and scroll
	function updateRect() {
		rect = container.getBoundingClientRect();
	}
}